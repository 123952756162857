// ToiletPaper.js

import React from 'react';

const ToiletPaper= ({ className }) => (

<svg className={className} fill="currentColor " version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 618 601">
<g transform="translate(0,601) scale(0.1,-0.1)">
<path d="M1110 5993 c-131 -33 -256 -134 -356 -288 -30 -47 -92 -160 -136
-252 -45 -93 -110 -208 -145 -257 -117 -164 -209 -354 -293 -606 -77 -229
-121 -446 -157 -765 -25 -223 -25 -815 0 -1024 55 -462 143 -779 303 -1092
140 -273 350 -516 552 -636 66 -39 210 -97 261 -105 19 -2 33 -26 81 -133 68
-156 101 -217 194 -355 126 -188 300 -343 472 -421 135 -61 67 -59 1500 -59
1282 0 1313 0 1390 20 339 87 660 412 848 862 138 330 202 752 171 1138 -3 41
-8 104 -11 140 -3 36 -16 138 -30 228 -25 160 -25 166 -10 285 30 235 39 374
39 589 l-1 216 152 4 c167 3 197 12 231 71 16 27 17 47 11 195 -34 857 -325
1767 -665 2085 -70 65 -163 123 -240 150 l-66 22 -2021 1 c-1660 0 -2031 -2
-2074 -13z m4128 -271 c163 -87 336 -364 462 -741 76 -227 158 -603 190 -866
6 -49 13 -103 15 -120 3 -16 8 -81 11 -142 l7 -113 -1856 0 -1855 0 -7 33 c-4
17 -72 259 -152 537 -79 278 -171 597 -203 710 -112 391 -193 591 -278 691
l-34 39 1829 -2 1828 -3 43 -23z m-3819 -323 l50 -11 35 -108 c20 -59 132
-448 251 -864 118 -416 225 -782 237 -814 20 -53 66 -113 95 -123 7 -2 15 -28
18 -57 16 -162 76 -443 149 -692 l25 -85 -35 -137 c-100 -401 -219 -700 -362
-914 -232 -347 -511 -462 -799 -329 -172 79 -350 252 -471 457 -192 326 -304
726 -342 1220 -16 202 -7 733 14 908 50 399 135 701 273 973 78 155 152 255
265 363 182 174 406 254 597 213z m1106 -2002 c253 -394 380 -586 406 -609 38
-36 81 -37 114 -3 14 13 25 35 25 47 0 13 -25 65 -55 116 -30 51 -55 94 -55
96 0 2 -58 100 -130 216 -71 117 -130 214 -130 216 0 2 30 4 68 4 l67 0 100
-158 c335 -530 358 -562 415 -562 37 0 80 41 80 78 0 24 -108 211 -281 489
-49 78 -89 144 -89 147 0 3 29 6 65 6 64 0 66 -1 91 -37 14 -21 113 -173 220
-338 108 -165 207 -310 220 -322 32 -29 76 -29 110 0 54 47 47 63 -210 480
-65 105 -120 198 -123 205 -4 9 13 12 63 12 l69 0 75 -117 c170 -267 352 -540
380 -572 27 -31 33 -33 72 -28 48 6 78 35 78 75 0 26 -65 141 -257 450 -62
101 -113 186 -113 188 0 2 31 4 69 4 l69 0 68 -107 c174 -277 368 -570 391
-590 65 -60 172 12 133 89 -25 47 -235 396 -295 488 -33 52 -64 101 -69 108
-5 9 10 12 64 12 l71 0 86 -132 c47 -73 144 -223 215 -333 141 -218 173 -255
217 -255 42 0 81 35 81 73 0 34 -97 209 -271 490 l-98 157 72 0 72 0 118 -188
c349 -555 332 -532 383 -532 24 0 39 8 58 31 14 17 26 39 26 49 0 34 -155 314
-308 558 l-52 82 70 0 70 0 53 -82 c189 -294 276 -478 360 -761 l36 -119 -25
6 c-39 10 -93 -27 -94 -65 0 -10 14 -43 31 -75 42 -79 98 -216 119 -289 21
-75 34 -309 24 -436 l-7 -94 -18 101 c-41 226 -201 622 -288 714 -31 33 -45
40 -76 40 -43 0 -85 -31 -85 -62 0 -10 27 -72 60 -138 82 -164 124 -269 165
-412 68 -234 92 -569 60 -813 -12 -88 -22 -125 -54 -192 -133 -277 -370 -490
-596 -538 -59 -12 -231 -15 -1090 -15 -561 0 -1029 3 -1041 6 -18 5 -14 12 41
68 205 209 325 559 325 946 0 105 -12 257 -25 320 -10 49 14 -6 49 -112 19
-56 45 -159 57 -228 23 -133 22 -230 -2 -391 -14 -91 -8 -124 29 -143 65 -34
119 12 148 126 26 104 26 369 0 488 -34 159 -115 357 -159 392 -35 28 -81 23
-110 -11 l-25 -30 -17 67 c-32 127 -88 288 -205 592 -65 168 -130 347 -144
398 l-25 93 40 -35 c48 -44 105 -50 139 -14 31 33 25 66 -29 153 -63 105 -315
528 -319 538 -2 4 27 7 65 5 l68 -3 50 -78z m-46 -1295 c2 -41 -89 -268 -175
-438 -46 -91 -84 -179 -84 -194 0 -56 72 -85 127 -51 35 22 113 148 190 306
l48 99 13 -45 c39 -134 82 -413 82 -534 l0 -55 -405 0 c-236 0 -405 4 -405 9
0 5 24 30 53 56 191 171 354 464 492 885 l28 85 18 -50 c10 -28 18 -60 18 -73z
m-821 -1284 c90 -189 255 -385 409 -488 l75 -50 -55 0 c-34 0 -74 8 -104 21
-108 46 -262 190 -364 341 -48 70 -139 244 -139 265 0 9 17 13 64 13 l64 0 50
-102z m330 38 c83 -161 193 -297 319 -398 60 -47 64 -53 47 -65 -41 -30 -107
-5 -218 86 -67 54 -140 138 -203 231 -36 53 -103 183 -103 199 0 7 23 10 63
10 l63 -1 32 -62z m314 54 c4 0 23 -30 42 -67 20 -38 67 -108 106 -157 72 -92
75 -103 36 -136 -13 -10 -21 -9 -43 5 -69 45 -194 200 -249 307 -13 26 -24 49
-24 51 0 2 62 1 132 -3z m338 -13 c0 -21 -31 -107 -38 -107 -4 0 -16 15 -28
33 -11 17 -29 44 -38 60 l-18 27 61 0 c44 0 61 -4 61 -13z"/>
<path d="M2067 5340 c-33 -26 -41 -63 -22 -100 31 -60 107 -70 149 -19 14 17
26 41 26 54 0 13 -12 37 -26 54 -31 38 -87 43 -127 11z"/>
<path d="M2403 5338 c-28 -31 -36 -60 -24 -90 19 -51 35 -58 137 -58 87 0 97
2 119 25 31 30 33 82 4 119 -20 25 -25 26 -118 26 -88 0 -99 -2 -118 -22z"/>
<path d="M2845 5335 c-17 -16 -25 -35 -25 -60 0 -25 8 -44 25 -60 24 -25 26
-25 214 -25 185 0 191 1 217 24 37 31 38 84 4 121 l-23 25 -194 0 c-193 0
-194 0 -218 -25z"/>
<path d="M3492 5340 c-12 -11 -25 -32 -28 -45 -8 -31 12 -82 37 -95 10 -6 65
-10 123 -10 116 0 138 10 152 65 8 31 -12 82 -37 95 -10 6 -64 10 -121 10 -91
0 -107 -3 -126 -20z"/>
<path d="M3965 5343 c-41 -40 -42 -110 -2 -138 15 -11 48 -15 112 -15 82 0 94
2 112 23 29 32 35 56 22 94 -15 46 -34 53 -138 53 -70 0 -93 -4 -106 -17z"/>
<path d="M4409 5350 c-9 -5 -22 -23 -29 -39 -15 -36 1 -96 30 -111 10 -6 59
-10 108 -10 79 0 91 2 109 23 11 12 23 31 27 41 9 26 -11 84 -34 96 -24 13
-190 13 -211 0z"/>
<path d="M4842 5340 c-29 -27 -37 -64 -22 -101 18 -42 37 -49 138 -49 75 0 93
3 116 21 35 28 37 95 3 127 -21 19 -35 22 -118 22 -82 0 -98 -3 -117 -20z"/>
<path d="M5302 5353 c-7 -3 -19 -18 -28 -35 -39 -76 39 -158 119 -124 102 42
74 167 -37 165 -22 0 -47 -3 -54 -6z"/>
<path d="M1176 4274 c-134 -49 -255 -214 -325 -439 -55 -182 -66 -265 -66
-525 1 -255 15 -361 71 -535 132 -411 424 -562 642 -331 213 225 298 829 187
1327 -73 329 -224 520 -410 518 -33 0 -78 -7 -99 -15z"/>
<path d="M4794 2296 c-43 -31 -39 -57 29 -188 64 -125 114 -253 162 -409 54
-181 80 -485 55 -649 -24 -154 -24 -156 -1 -179 31 -31 81 -28 114 7 50 52 62
128 62 402 -1 277 -10 337 -85 562 -46 138 -158 371 -204 424 -37 42 -97 56
-132 30z"/>
<path d="M4472 2197 c-12 -13 -22 -33 -22 -44 0 -11 31 -85 69 -164 116 -241
171 -436 193 -687 11 -126 4 -292 -18 -392 -9 -40 29 -80 75 -80 73 0 100 57
121 256 21 203 -10 456 -85 683 -49 147 -166 381 -211 420 -43 39 -91 42 -122
8z"/>
<path d="M4182 2070 c-33 -31 -27 -67 33 -191 61 -129 115 -284 142 -409 14
-67 18 -130 18 -305 0 -121 -2 -242 -5 -269 -4 -44 -2 -51 24 -73 23 -19 36
-23 59 -19 84 17 102 83 102 371 0 173 -4 238 -18 305 -43 202 -173 520 -238
581 -38 35 -85 39 -117 9z"/>
<path d="M3882 1960 c-38 -36 -36 -58 13 -164 60 -128 110 -277 132 -396 22
-120 25 -425 4 -548 -13 -80 -13 -85 6 -110 16 -22 28 -27 63 -27 55 0 74 24
103 131 18 69 21 106 21 294 1 173 -3 232 -18 300 -43 199 -150 455 -213 510
-40 35 -80 39 -111 10z"/>
<path d="M3600 1863 c-56 -22 -63 -67 -24 -145 105 -207 162 -531 135 -759
-26 -211 -26 -195 2 -223 46 -46 108 -29 137 36 41 95 57 384 31 567 -24 169
-126 447 -183 498 -25 24 -73 36 -98 26z"/>
<path d="M3251 1764 c-12 -15 -21 -34 -21 -43 0 -9 18 -60 39 -113 50 -121 77
-218 95 -338 17 -116 20 -276 6 -350 -22 -121 -23 -158 -7 -184 35 -53 120
-37 152 30 8 17 22 73 31 125 20 115 15 353 -11 472 -34 162 -123 380 -167
411 -36 26 -93 21 -117 -10z"/>
</g>
</svg>


);

ToiletPaper.defaultProps = {
  className: "ToiletPaperSvg", // This is the default class name
};

export default ToiletPaper;