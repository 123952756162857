// FlushLoo.js

import React from 'react';

const FlushLoo = ({ className }) => (

<svg className={className} fill="currentColor " xmlns="http://www.w3.org/2000/svg"
width="100%" height="100%" viewBox="0 0 803 803">
<g transform="translate(0,803) scale(0.1,-0.1)">
<path d="M415 7908 c-27 -5 -83 -25 -124 -44 -171 -83 -290 -272 -291 -461 l0
-53 1315 0 1315 0 0 38 c-1 128 -64 275 -160 372 -76 77 -140 113 -246 140
-74 19 -111 20 -918 19 -482 -1 -862 -5 -891 -11z"/>
<path d="M3230 7310 l0 -610 -265 0 -266 0 78 -77 c44 -43 202 -207 352 -365
151 -159 277 -288 281 -288 6 0 278 277 571 583 l137 142 -262 5 -261 5 -3
608 -2 607 -180 0 -180 0 0 -610z"/>
<path d="M6282 7597 l-303 -303 233 -240 c128 -132 357 -368 508 -524 447
-464 711 -733 713 -728 1 2 137 139 301 305 l299 301 -254 263 c-140 145 -389
403 -554 574 -165 170 -376 388 -470 483 l-170 172 -303 -303z"/>
<path d="M3 5413 c3 -1719 3 -1728 24 -1813 30 -122 59 -201 113 -317 142
-300 356 -521 645 -666 181 -90 345 -131 590 -146 88 -5 193 -17 232 -25 451
-96 805 -444 909 -892 28 -118 26 -313 -4 -424 -50 -184 -156 -331 -334 -462
-22 -16 81 -17 1834 -18 l1856 0 -53 55 c-98 100 -178 302 -205 517 -5 45 -10
152 -10 238 l0 155 137 60 c245 106 421 225 616 415 281 273 459 580 554 955
30 119 52 296 53 418 l0 87 -2440 0 -2440 0 0 1795 0 1795 -1040 0 -1041 0 4
-1727z m573 1229 l152 -163 -24 -54 c-52 -112 -67 -268 -39 -380 46 -179 209
-378 362 -442 l42 -18 4 -820 c4 -933 -1 -873 92 -1060 133 -269 363 -438 655
-485 43 -6 789 -10 2203 -10 1475 0 2137 -3 2137 -10 0 -70 -73 -293 -128
-390 -98 -172 -252 -345 -406 -454 -394 -280 -928 -376 -1432 -256 -375 89
-734 344 -964 684 l-50 75 -962 4 c-1060 4 -1016 1 -1186 67 -270 104 -503
358 -590 642 -54 175 -52 96 -52 1739 0 1421 1 1521 17 1508 9 -8 85 -87 169
-177z m819 -46 c118 -31 214 -106 267 -209 60 -116 64 -275 10 -388 -38 -80
-118 -159 -199 -197 -53 -25 -68 -27 -183 -27 -139 0 -179 12 -261 77 -38 30
-119 139 -119 160 0 4 51 8 114 8 105 0 117 -2 162 -27 130 -73 301 10 329
159 9 47 -17 137 -51 178 -65 76 -177 96 -275 48 -51 -26 -66 -28 -173 -28
l-117 0 23 41 c13 22 37 57 55 78 95 113 272 167 418 127z m1430 -4545 c77
-85 190 -212 252 -282 121 -140 286 -322 630 -695 l222 -242 -37 -5 c-54 -9
-611 -9 -859 -1 l-212 7 30 59 c69 138 106 365 90 555 -23 257 -107 490 -266
736 -75 116 -79 120 150 -132z"/>
<path d="M2290 5450 l0 -1690 164 2 163 3 7 1688 7 1687 -171 0 -170 0 0
-1690z"/>
<path d="M5735 7041 l-100 -98 -80 -7 c-127 -11 -284 -45 -365 -79 -163 -67
-250 -127 -415 -286 -71 -70 -135 -130 -141 -136 -7 -5 -319 -316 -694 -690
-777 -775 -742 -734 -744 -893 -1 -76 2 -94 27 -144 31 -62 84 -116 149 -149
66 -34 185 -38 262 -9 56 22 93 56 633 589 392 388 583 570 606 578 18 6 55
15 82 19 l50 7 -135 -128 c-238 -226 -267 -259 -290 -322 -14 -41 -31 -65 -53
-80 -18 -13 -71 -60 -118 -105 -110 -107 -140 -166 -147 -289 -4 -78 -2 -92
23 -146 65 -146 206 -215 365 -179 72 16 60 22 120 -57 28 -35 95 -81 142 -96
20 -7 68 -12 107 -12 89 0 138 21 216 93 l59 55 48 -49 c26 -27 73 -60 104
-75 50 -23 65 -25 142 -21 72 4 97 10 148 35 76 39 161 124 203 202 33 63 71
193 71 246 0 28 30 61 268 296 182 180 278 282 300 321 58 98 103 216 125 324
12 57 25 104 29 104 3 0 90 -87 192 -192 l186 -193 84 84 85 85 -36 40 c-20
23 -102 109 -182 191 -80 83 -265 274 -411 425 -145 151 -348 361 -450 465
-102 105 -226 232 -275 282 l-90 93 -100 -99z m300 -443 l166 -172 -140 -140
c-136 -137 -141 -143 -162 -211 -12 -38 -30 -88 -41 -110 -13 -25 -185 -205
-456 -477 l-437 -438 -43 0 c-37 0 -50 6 -78 34 -27 27 -34 42 -34 72 1 22 6
48 13 59 7 11 120 126 252 255 131 129 281 276 332 326 51 50 93 99 93 108 0
30 -39 94 -73 120 -62 48 -126 69 -222 74 -105 5 -186 -12 -279 -59 -82 -42
-82 -41 -341 -297 -126 -125 -390 -385 -585 -577 -195 -193 -370 -362 -389
-377 -18 -16 -45 -28 -58 -28 -65 0 -124 75 -104 131 9 27 737 756 1396 1399
266 260 311 288 575 361 331 92 426 118 437 118 6 1 86 -76 178 -171z m290
-663 c-26 -267 -197 -483 -452 -569 -49 -16 -69 -28 -73 -43 -3 -11 -168 -186
-367 -388 l-361 -368 -39 6 c-25 4 -48 17 -65 37 -56 61 -64 50 346 456 338
335 378 372 414 378 63 10 181 62 255 112 79 54 178 158 225 237 47 78 102
207 103 242 l1 30 9 -35 c5 -19 7 -62 4 -95z m-1715 -965 c0 -16 81 -100 113
-116 l28 -15 -28 -33 c-48 -57 -88 -79 -129 -73 -44 7 -72 31 -90 74 -18 42
-6 75 49 131 41 42 57 51 57 32z m1164 -90 c41 -46 53 -82 38 -115 -18 -39
-154 -177 -189 -192 -73 -30 -149 42 -122 116 11 29 207 231 225 231 6 0 28
-18 48 -40z"/>
<path d="M3122 4175 c-171 -38 -289 -178 -305 -362 l-4 -53 2075 0 2075 0 -6
67 c-9 96 -51 176 -131 251 -42 39 -85 68 -123 84 l-58 23 -1730 2 c-1442 1
-1741 -1 -1793 -12z"/>
<path d="M1880 275 l0 -165 2125 0 2125 0 2 103 c1 56 3 125 3 152 1 28 0 56
-3 63 -3 9 -438 12 -2128 12 l-2124 0 0 -165z"/>
</g>
</svg>

);

FlushLoo.defaultProps = {
  className: "FlushLooSvg", // This is the default class name
};

export default FlushLoo;