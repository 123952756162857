// DumpNow.js

import React from 'react';

const DumpNow = ({ className }) => (

  <svg className={className} fill="currentColor " xmlns="http://www.w3.org/2000/svg"
 width="100%" height="100%" viewBox="0 0 688 688">
<g transform="translate(0,688) scale(0.1,-0.1)">
<path d="M2519 6851 l-29 -29 0 -331 0 -331 -418 0 c-366 0 -420 -2 -440 -16
-16 -11 -22 -24 -20 -42 2 -23 50 -60 288 -230 157 -112 404 -290 550 -397
495 -363 705 -514 738 -530 21 -11 44 -14 63 -10 16 4 98 57 182 119 84 61
231 169 327 239 96 70 290 211 430 313 140 103 334 243 430 312 243 174 252
183 210 220 -19 15 -60 17 -423 22 l-402 5 -5 333 -5 334 -28 24 -28 24 -695
0 -696 0 -29 -29z"/>
<path d="M1539 5670 c-9 -5 -22 -23 -29 -40 -16 -40 2 -85 42 -106 83 -43 160
65 93 131 -25 26 -75 33 -106 15z"/>
<path d="M4675 5518 c-51 -28 -61 -88 -20 -132 41 -44 105 -31 130 27 17 40 2
83 -36 102 -33 18 -47 18 -74 3z"/>
<path d="M2146 5424 c-38 -19 -55 -43 -56 -77 0 -41 25 -82 59 -97 26 -10 36
-9 68 6 66 33 79 95 30 148 -29 31 -66 39 -101 20z"/>
<path d="M1536 5339 c-42 -33 -35 -99 15 -124 40 -21 57 -19 90 14 36 36 37
73 4 106 -30 31 -73 32 -109 4z"/>
<path d="M4204 5310 c-37 -15 -54 -50 -54 -113 0 -94 -41 -155 -117 -171 -43
-10 -83 -49 -83 -81 0 -70 56 -112 135 -102 134 19 247 141 251 272 3 84 -10
153 -32 176 -23 22 -71 32 -100 19z"/>
<path d="M4655 5185 c-14 -13 -25 -35 -25 -49 0 -31 37 -73 71 -81 37 -9 89
36 89 76 0 40 -40 79 -80 79 -19 0 -40 -9 -55 -25z"/>
<path d="M1785 5073 c-39 -32 -137 -112 -219 -178 -188 -150 -258 -215 -252
-230 4 -11 226 -297 312 -402 19 -24 43 -43 53 -43 10 0 89 62 175 138 138
121 160 144 186 200 16 35 55 89 85 121 l55 58 -145 194 c-80 107 -154 195
-163 197 -10 2 -48 -23 -87 -55z m95 -223 l21 -30 -35 0 c-22 0 -43 -7 -55
-19 -16 -16 -81 -177 -81 -202 0 -5 14 -9 31 -9 l32 0 -44 -49 c-24 -27 -48
-49 -54 -49 -10 -1 -130 157 -124 162 32 22 113 87 184 148 50 43 93 78 97 78
4 0 16 -13 28 -30z"/>
<path d="M2448 4833 c-11 -27 -34 -77 -50 -113 -46 -101 -205 -472 -212 -498
-8 -25 -17 -20 179 -100 89 -36 228 -90 410 -157 33 -12 66 -20 73 -18 7 3 23
26 36 51 35 71 226 517 226 530 0 20 -590 352 -626 352 -8 0 -24 -21 -36 -47z
m264 -299 c79 -42 146 -79 151 -83 6 -6 -70 -194 -100 -245 -10 -19 -17 -18
-169 38 -88 32 -161 60 -163 62 -5 5 127 304 135 304 2 0 68 -34 146 -76z"/>
<path d="M4667 4739 c-24 -14 -47 -62 -47 -101 -1 -118 -122 -207 -224 -164
-53 22 -89 20 -113 -6 -30 -34 -34 -55 -19 -92 31 -73 114 -112 226 -103 124
9 225 79 281 192 55 112 50 242 -10 275 -25 13 -71 12 -94 -1z"/>
<path d="M3526 4708 c-9 -13 -16 -43 -16 -70 l0 -48 -65 0 c-53 0 -66 -3 -75
-19 -29 -55 5 -91 87 -91 l53 0 0 -50 c0 -37 5 -55 20 -70 46 -46 100 -9 100
70 l0 50 59 0 c68 0 91 15 91 59 0 41 -21 53 -90 54 l-60 1 0 51 c0 38 -5 56
-18 68 -26 24 -68 21 -86 -5z"/>
<path d="M3923 4158 c-9 -11 -105 -314 -154 -486 -7 -23 -16 -41 -20 -40 -4 2
-25 26 -46 54 -136 183 -274 364 -282 368 -9 6 -168 -108 -450 -322 l-133
-101 -542 -3 -542 -3 -76 -38 c-92 -45 -146 -102 -195 -204 -42 -86 -51 -170
-34 -318 44 -402 68 -609 121 -1030 56 -457 137 -1167 160 -1415 18 -188 53
-283 141 -375 27 -29 49 -54 49 -56 0 -2 -70 -6 -155 -7 -129 -3 -155 -1 -155
11 0 8 14 67 30 131 17 64 29 120 26 125 -3 5 -25 14 -49 20 -44 10 -46 14
-192 236 -82 124 -150 225 -152 225 -2 0 -40 -25 -85 -55 -45 -30 -87 -55 -93
-55 -7 0 -95 27 -196 59 -101 32 -188 56 -192 52 -4 -4 -19 -91 -34 -192 -14
-101 -30 -190 -36 -196 -5 -7 -18 -13 -28 -13 -25 0 -25 -18 6 -191 13 -78 22
-145 19 -150 -3 -5 -101 -9 -219 -9 l-213 0 -31 -26 c-19 -16 -31 -35 -31 -50
0 -33 25 -80 46 -88 10 -4 1008 -6 2218 -6 2416 0 2237 -4 2251 60 13 58 -34
120 -92 120 -12 0 -4 13 29 46 49 48 109 152 127 221 7 23 23 170 36 325 96
1099 174 1898 185 1898 6 0 51 -133 144 -430 30 -96 91 -292 136 -435 135
-428 252 -804 401 -1284 39 -124 75 -234 81 -245 6 -12 29 -40 51 -63 l41 -43
-83 0 c-95 0 -117 -9 -137 -55 -16 -38 -8 -69 23 -97 17 -16 52 -18 332 -21
345 -4 366 -1 385 56 20 55 -31 120 -94 120 -36 0 -38 9 -6 30 13 9 37 42 53
74 25 52 28 68 28 158 -1 111 9 75 -200 720 -48 149 -87 274 -88 279 -1 5 25
14 58 21 81 15 129 40 170 87 34 40 65 113 65 153 0 64 -168 585 -208 644 -16
24 -49 56 -72 72 -78 53 -147 60 -263 30 -47 -12 -70 -14 -72 -7 -143 457
-256 797 -280 846 -75 148 -237 245 -401 238 -40 -1 -78 2 -85 8 -51 40 -158
82 -225 90 -30 4 -54 7 -54 8 0 7 72 281 75 286 12 20 -64 46 -500 170 -137
39 -257 73 -266 76 -9 2 -21 -1 -26 -8z m346 -288 c108 -33 201 -62 208 -65 9
-3 4 -28 -16 -87 l-28 -83 -236 -3 -236 -2 15 47 c8 27 26 91 40 143 29 106
31 110 46 110 6 0 99 -27 207 -60z m-834 -159 c31 -40 54 -75 52 -77 -2 -3
-77 -3 -166 -2 l-162 3 103 77 c57 42 106 75 110 75 3 -1 32 -35 63 -76z
m1264 -317 c65 -33 88 -65 72 -100 -6 -14 -11 -38 -11 -55 l0 -29 -1552 2
-1553 3 3 35 c4 53 49 111 111 143 l55 27 1411 0 1411 0 53 -26z m374 -79 c52
-22 105 -72 131 -124 23 -46 123 -344 276 -821 45 -140 113 -349 150 -465 38
-115 101 -313 140 -440 40 -126 98 -313 130 -415 197 -622 203 -649 151 -701
-42 -41 -103 -35 -135 13 -14 21 -110 319 -293 903 -41 132 -138 438 -215 680
-77 242 -166 526 -199 630 -33 105 -100 314 -149 466 -50 153 -90 280 -90 283
0 12 68 6 103 -9z m-327 -347 c-11 -86 -26 -237 -41 -418 -9 -102 -27 -297
-40 -435 -58 -583 -103 -1064 -134 -1435 -6 -69 -17 -148 -26 -177 -32 -107
-115 -192 -232 -236 l-58 -22 -950 -3 c-656 -2 -966 1 -1002 8 -79 17 -165 64
-218 121 -80 85 -87 114 -120 444 -32 333 -221 1946 -251 2153 l-6 42 1541 0
1542 0 -5 -42z m1165 -765 c48 -127 169 -487 169 -504 0 -13 -13 -20 -57 -29
-32 -7 -63 -14 -69 -17 -8 -3 -45 96 -104 279 -51 156 -95 288 -97 294 -2 7
21 15 59 21 35 6 66 11 70 12 4 0 16 -25 29 -56z m-4623 -1591 l53 -77 -50 -5
-49 -5 -112 -153 -112 -152 -77 82 c-135 146 -164 178 -160 182 2 2 37 11 78
21 85 21 81 23 127 -67 13 -27 27 -48 30 -48 3 0 25 14 50 31 l44 30 -39 51
-39 52 22 17 c39 31 169 119 175 119 3 0 30 -35 59 -78z m120 -247 c28 -4 52
-10 52 -13 0 -4 -11 -41 -25 -83 -25 -74 -27 -77 -63 -83 -20 -3 -68 -6 -106
-6 -83 0 -83 0 -1 118 41 59 62 81 73 78 10 -2 41 -8 70 -11z"/>
<path d="M5885 713 c-21 -20 -17 -52 20 -183 35 -125 45 -144 76 -148 52 -6
54 16 15 154 -20 69 -36 132 -36 140 0 16 -35 54 -49 54 -5 0 -17 -7 -26 -17z"/>
<path d="M2155 2718 c-54 -31 -56 -42 -50 -198 3 -80 10 -203 15 -275 5 -71
14 -213 20 -315 6 -102 15 -246 20 -320 6 -74 19 -279 30 -455 11 -176 23
-360 26 -410 8 -128 32 -165 106 -165 42 0 76 23 93 64 15 35 4 312 -35 856
-11 157 -31 465 -45 685 -29 453 -31 471 -51 503 -25 39 -88 53 -129 30z"/>
<path d="M2714 2719 c-11 -6 -28 -23 -37 -39 -16 -26 -17 -112 -17 -1030 l0
-1002 34 -34 c24 -24 43 -34 65 -34 44 0 86 24 100 57 8 21 11 289 9 1031 l-3
1004 -33 29 c-34 31 -78 37 -118 18z"/>
<path d="M3214 2696 l-34 -34 2 -1011 3 -1011 26 -26 c16 -15 41 -28 66 -31
35 -5 45 -1 74 25 l34 30 3 1012 2 1012 -34 34 c-26 26 -42 34 -71 34 -29 0
-45 -8 -71 -34z"/>
<path d="M3754 2711 c-17 -11 -36 -34 -43 -52 -8 -23 -11 -310 -11 -1016 0
-1069 -2 -1025 55 -1051 56 -26 123 -4 144 45 8 21 11 293 9 1038 l-3 1009
-34 23 c-41 28 -76 29 -117 4z"/>
<path d="M4299 2701 c-16 -16 -29 -38 -30 -48 0 -10 -2 -27 -4 -38 -2 -11 -11
-141 -20 -290 -9 -148 -25 -405 -36 -570 -54 -824 -69 -1082 -64 -1103 17 -69
120 -98 170 -48 31 31 31 35 55 406 11 173 27 403 35 510 8 107 26 371 40 585
13 215 27 420 30 458 6 82 -10 129 -54 151 -47 25 -88 20 -122 -13z"/>
<path d="M1743 3986 c-88 -29 -149 -82 -183 -158 -19 -43 -19 -50 -6 -82 30
-71 103 -73 158 -5 60 74 135 91 199 44 19 -14 49 -32 67 -40 30 -14 36 -14
67 1 45 21 63 70 42 111 -20 39 -63 80 -114 110 -53 31 -166 40 -230 19z"/>
<path d="M4904 170 c-30 -12 -54 -49 -54 -83 0 -36 49 -87 84 -87 59 0 109 52
99 105 -10 51 -79 86 -129 65z"/>
<path d="M5233 170 c-28 -11 -43 -40 -43 -83 0 -27 7 -41 31 -61 49 -42 108
-29 136 29 16 33 16 37 0 70 -23 48 -72 66 -124 45z"/>
<path d="M6538 160 c-48 -37 -41 -118 13 -145 36 -19 112 -19 149 0 56 29 60
113 8 149 -34 24 -138 22 -170 -4z"/>
</g>
</svg>

);

DumpNow.defaultProps = {
  className: "DumpNowSvg", // This is the default class name
};

export default DumpNow;